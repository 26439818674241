import { Autorenew, DeleteForever } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PhoneFrame from 'assets/phone-frame-panel.svg';
import { CARD_GROUP_TYPES, extractCardPropertiesForRendering } from 'components/dashboard/cardLayouts/cardhelper';
import WarningPanel from 'components/warningPanel/warningPanel';
import { colorWithAlpha } from 'helpers/color-util';
import { ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import HeaderUnderlayLayout from '../../../../../components/dashboard/headerUnderlayLayout';

const buildStyles = ({ theme }) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  cardGroup: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  processingArea: {
    position: 'absolute',
    top: '-8px',
    left: 0,
    paddingTop: '12px',
    color: theme.palette.grey[600],
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '4px',
    width: '375px',
    height: '48px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  creatingArea: {
    background: theme.palette.blue[50],
    border: `2px dashed ${theme.palette.blue[300]}`,
  },
  deleteArea: {
    background: theme.palette.red[50],
    border: `2px dashed ${theme.palette.red[200]}`,
  },
  deletingArea: {
    background: theme.palette.grey[300],
    border: `2px dashed ${theme.palette.grey[400]}`,
  },
  imageContainer: {
    position: 'relative',
    maxHeight: '888px',
    width: '376px',
  },
  image: {
    position: 'absolute',
    top: '42px',
    left: 0,
    width: '376px',
    height: '812px',
    zIndex: 1,
  },
  previewContent: {
    position: 'absolute',
    top: '132px',
    left: '8px',
    width: '360px',
    height: '612px',
    maxHeight: '612px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    borderBottom: `1px solid ${colorWithAlpha(theme.palette.black, 0.1)}`,
    background: theme.palette.white,
    textAlign: 'center',
    color: colorWithAlpha(theme.palette.black, 0.6),
    zIndex: 2,
  },
  previewContentBody: {
    lineHeight: '1.25rem',
  },
  previewContentTitle: {
    paddingTop: '269px',
    marginBottom: '7px',
  },
  selectablePreview: {
    cursor: 'pointer',
  },
  subtitleContainer: {
    marginBottom: `-${theme.spacing(2)}`,
    marginLeft: theme.spacing(1),
    position: 'relative',
    width: '376px',
  },
  trashIcon: {
    fontSize: '18px',
    marginTop: '-3px',
  },
  warningMessage: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  rotateIcon: {
    animation: '$spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

const DashboardPreview = ({
                            sx,
                            cardGroups,
                            headerUnderlay,
                            configColors,
                            editPanelTabValue,
                            incompleteForPackaging,
                            isCardGroupCreating,
                            onCardClick,
                            onCardGroupClick,
                            onBackgroundOptionClick,
                            onCardGroupDeleted,
                            onBackgroundOptionDeleted,
                            onCardGroupDrop,
                            onBackgroundOptionDrop,
                            onCardGroupMoved,
                            selectedCard,
                            selectedCardGroup,
                            selectedBackgroundOption,
                            modifiedSelectedCardProps,
                            modifiedSelectedCardGroupProps,
                            modifiedSelectedBackgroundOptionProps,
                            updateCardGroups,
                          }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [newCardGroupIndex, setNewCardGroupIndex] = useState(null);
  const [showDeleteArea, setShowDeleteArea] = useState(false);
  const [isCardGroupDeleting, setIsCardGroupDeleting] = useState(false);
  const [, deleteDrop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    drop: async (item, monitor) => {
      setIsCardGroupDeleting(true);
      switch (item.type) {
        case ItemTypes.DASHBOARD_OPTION:
          setNewCardGroupIndex(null);
          await onBackgroundOptionDeleted(item);
          break;
        default:
          setNewCardGroupIndex(null);
          if (item.preview) {
            await onCardGroupDeleted(item);
          }
      }
      setIsCardGroupDeleting(false);
    },
    canDrop: (item) => !selectedCard && item.preview,
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
      hovered: monitor.isOver(),
    }),
  });

  const [{ hovered: dropHovered }, addDrop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    drop: (item, monitor) => {
      setNewCardGroupIndex(null);
      setShowDeleteArea(false);
      switch (item.type) {
        case ItemTypes.DASHBOARD_OPTION:
          if (!item.preview) {
            onBackgroundOptionDrop(item);
          }
          break;
        default:
          if (!item.preview) {
            onCardGroupDrop(
              item,
              newCardGroupIndex === null ? cardGroups.length : newCardGroupIndex,
            );
          } else {
            updateCardGroups();
          }
          break;
      }
    },
    canDrop: (item) => !showDeleteArea && !selectedCard && !selectedCardGroup,
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
      hovered: monitor.isOver(),
    }),
  });

  const moveDashboardItem = (dragIndex, hoverIndex, item) => {
    switch (item.type) {
      case ItemTypes.DASHBOARD_OPTION: // do nothing
        break;
      default:
        setNewCardGroupIndex(null);
        setShowDeleteArea(false);
        if (typeof dragIndex !== 'undefined') {
          if (item.preview) {
            onCardGroupMoved(dragIndex, hoverIndex);
          }
        }
        break;
    }
  };

  const onDrag = (item, isDragging) => {
    if (isDragging) {
      if (!(isCardGroupCreating || isCardGroupDeleting)) {
        setShowDeleteArea(true);
      }
    } else {
      setShowDeleteArea(false);
    }
  };

  const onHover = useCallback(
    (item, hoverIndex) => {
      switch (item.type) {
        case ItemTypes.DASHBOARD_OPTION:
          if (headerUnderlay) {
            setShowDeleteArea(true);
          }
          break;
        default:
          const exist = cardGroups.find((c) => c.id === item.id);
          if (exist) {
            if (!(isCardGroupCreating || isCardGroupDeleting)) {
              setShowDeleteArea(true);
              setNewCardGroupIndex(null);
            }
          } else {
            setNewCardGroupIndex(hoverIndex);
          }
      }
    },
    [cardGroups, headerUnderlay, isCardGroupCreating, isCardGroupDeleting],
  );

  const renderProcessingArea = (text, sx) => (
    <Box sx={{ ...styles.processingArea, ...sx }} data-test="processing">
      <Grid container justifyContent="center" sx={{ marginTop: '1px' }}>
        <Grid item sx={{ marginTop: '-2px' }}>
          <Autorenew sx={styles.rotateIcon} />
        </Grid>
        <Grid item sx={{ marginLeft: '8px' }}>
          {text}
        </Grid>
      </Grid>
    </Box>
  );

  const renderDashboardItems = () => {
    const itemsToRender = [];
    const isDashboardItemSelected = (selectedCard != null) || (selectedCardGroup != null) || (selectedBackgroundOption != null);
    if (headerUnderlay !== null) {
      const { underlayHeaderColor, underlayTextColor, numberOfCards } = headerUnderlay;

      const isHeaderUnderlaySelected = selectedBackgroundOption != null;

      itemsToRender.push(
        <HeaderUnderlayLayout
          key={'headerUnderlay'}
          id={'headerUnderlay'}
          index={0}
          underlayHeaderColor={modifiedSelectedBackgroundOptionProps?.underlayHeaderColor || underlayHeaderColor}
          underlayTextColor={modifiedSelectedBackgroundOptionProps?.underlayTextColor || underlayTextColor}
          numberOfCards={modifiedSelectedBackgroundOptionProps?.numberOfCards || numberOfCards}
          preview={true}
          disabled={isDashboardItemSelected === true && isHeaderUnderlaySelected === false}
          selected={isHeaderUnderlaySelected === true}
          hovered={dropHovered}
          moveCard={moveDashboardItem}
          onDrag={onDrag}
          onHover={onHover}
          onBackgroundOptionClick={isDashboardItemSelected ? () => {
          } : onBackgroundOptionClick}
          canDragAndDrop={isDashboardItemSelected === false}
        />);
    }

    cardGroups.forEach((cardGroup, index) => {
      const { cardType, id, type, cardProps } = cardGroup;

      // For now, this is the only card group we handle in this manner. Later, we can identify things as dashboard items,
      // independently.
      if (type === CARD_GROUP_TYPES.CYCLE || type === CARD_GROUP_TYPES.NAV_LINKS) {
        const isSelectedGroup = (selectedCardGroup != null) && id === selectedCardGroup.cardGroupId;

        const cardGroupProps = modifiedSelectedCardGroupProps && isSelectedGroup ? {
          ...cardGroup,
          ...modifiedSelectedCardGroupProps.cardGroup,
        } : cardGroup;

        const CardGroupType = cardType;
        const cardGroupFuncProps = isDashboardItemSelected === true
          ? {}
          : {
            hovered: dropHovered,
            moveCard: moveDashboardItem,
            onCardGroupClick,
            onDrag,
            onHover,
          };

        const cardGroupVariableProps = {
          key: id,
          configColors,
          id,
          index: headerUnderlay ? index + 1 : index,
          preview: true,
          selected: isSelectedGroup === true,
          disabled: isDashboardItemSelected === true && isSelectedGroup === false,
          canDragAndDrop: isDashboardItemSelected === false,
          ...cardGroupProps,
        };

        itemsToRender.push(
          <CardGroupType
            {...cardGroupVariableProps}
            {...cardGroupFuncProps}
          />,
        );
        return;
      }
      // Handling the dashboard card items below.
      const isSelectedCard = (selectedCard != null) && id === selectedCard.cardGroupId;
      const updatedCardProps = modifiedSelectedCardProps && isSelectedCard
        ? {
          ...cardProps,
          card1: { ...cardProps.card1, ...modifiedSelectedCardProps.card1 },
          ...cardProps.card2 && {
            card2: { ...cardProps.card2, ...modifiedSelectedCardProps.card2 },
          },
          ...cardProps.card3 && {
            card3: { ...cardProps.card3, ...modifiedSelectedCardProps.card3 },
          },
          ...cardProps.card4 && {
            card4: { ...cardProps.card4, ...modifiedSelectedCardProps.card4 },
          },
        } : cardProps;

      const props = extractCardPropertiesForRendering(
        updatedCardProps,
        isDashboardItemSelected,
        selectedCard,
        isSelectedCard ? editPanelTabValue : 0,
        type,
      );

      const cardVariableProps = {
        key: id,
        configColors,
        id,
        index: headerUnderlay ? index + 1 : index,
        preview: true,
        canDragAndDrop: isDashboardItemSelected === false,
        ...props,
      };

      const funcProps = isDashboardItemSelected === true
        ? {}
        : {
          hovered: dropHovered,
          moveCard: moveDashboardItem,
          onCardClick,
          onDrag,
          onHover,
        };

      const CardType = cardType;
      itemsToRender.push(
        <CardType
          {...cardVariableProps}
          {...funcProps}
        />
      );
    });
    return itemsToRender;
  };

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      <Box sx={styles.subtitleContainer}>
        {incompleteForPackaging && (
          <WarningPanel
            sx={styles.warningMessage}
            message="The Dashboard is incomplete."
            disableClose={true}
          />
        )}
        <Typography variant="subtitle1">Mobile Preview</Typography>
      </Box>
      <Box sx={styles.imageContainer}>
        {isCardGroupCreating
          && renderProcessingArea(
            'Adding Card. System Processing...',
            styles.creatingArea,
          )}
        {isCardGroupDeleting
          && renderProcessingArea(
            'Deleting Card. System Processing...',
            styles.deletingArea,
          )}
        {showDeleteArea && (
          <Box
            ref={deleteDrop}
            sx={{ ...styles.processingArea, ...styles.deleteArea }}
            data-test="delete-area"
          >
            <Grid container justifyContent="center" sx={{ marginTop: '1px' }}>
              <Grid item sx={{ marginTop: '2px' }}>
                <DeleteForever sx={styles.trashIcon} />
              </Grid>
              <Grid item>Delete Card</Grid>
            </Grid>
          </Box>
        )}
        <img src={PhoneFrame} alt="Preview" style={styles.image} />
        <Box
          ref={addDrop}
          sx={{
            ...styles.previewContent,
            ...!selectedCard && styles.selectablePreview,
          }}
          data-test="preview-content"
        >
          {cardGroups.length > 0 && renderDashboardItems()}
          {cardGroups.length === 0 && (
            <>
              <Typography variant="h2" sx={styles.previewContentTitle}>
                Nothing to see here
              </Typography>
              <Typography variant="body1" sx={styles.previewContentBody}>
                Double-click or drag and drop to add a<br />
                card type to your layout.
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

DashboardPreview.propTypes = {
  configColors: PropTypes.object,
  incompleteForPackaging: PropTypes.bool,
  cardGroups: PropTypes.array,
  headerUnderlay: PropTypes.object,
  editPanelTabValue: PropTypes.number,
  isCardGroupCreating: PropTypes.bool,
  onCardClick: PropTypes.func.isRequired,
  onCardGroupClick: PropTypes.func.isRequired,
  onCardGroupDrop: PropTypes.func.isRequired,
  onBackgroundOptionDrop: PropTypes.func.isRequired,
  onCardGroupDeleted: PropTypes.func.isRequired,
  onCardGroupMoved: PropTypes.func.isRequired,
  selectedCard: PropTypes.shape({
    backgroundColor: PropTypes.string,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    waitTimeInMinutes: PropTypes.number,
    waitTimeLabel: PropTypes.string,
    isExpandable: PropTypes.bool,
    dismissable: PropTypes.bool,
    expires: PropTypes.any,
    overline: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    subtitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    ]),
    textAlignment: PropTypes.oneOf(['left', 'center']),
    textColor: PropTypes.string,
    title: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ).isRequired,
  }),
  selectedCardGroup: PropTypes.shape({
    groupTitle: PropTypes.arrayOf(
      PropTypes.shape({
        lang: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    showGroupTitle: PropTypes.bool,
    groupTitleTextColor: PropTypes.string,
    showButtons: PropTypes.bool,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    visibilityRule: PropTypes.object,
    visibilityRuleCustom: PropTypes.string,
  }),
  modifiedSelectedCardProps: PropTypes.object,
  modifiedSelectedCardGroupProps: PropTypes.object,
  modifiedSelectedBackgroundOptionProps: PropTypes.object,
  sx: PropTypes.object,
  updateCardGroups: PropTypes.func.isRequired,
};

DashboardPreview.defaultProps = {
  cardGroups: [],
  configColors: {},
  editPanelTabValue: 0,
  incompleteForPackaging: false,
  isCardGroupCreating: false,
  modifiedSelectedCardProps: null,
  modifiedSelectedCardGroupProps: null,
  modifiedSelectedBackgroundOptionProps: null,
  selectedCard: null,
  selectedCardGroup: null,
  sx: {},
};

export default DashboardPreview;
