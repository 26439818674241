import { Grid } from '@mui/material';
import Asterisk from 'components/asterisk/asterisk';
import {
  A2C_DISPLAY_TYPES,
  buildI18NValues,
  CARD_GROUP_TYPES,
  DEFAULT_BACKGROUND_COLOR,
  getLanguageLabel,
} from 'components/dashboard/cardLayouts/cardhelper';
import TextField from 'components/textField/textField';
import { isPastDate } from 'helpers/date-util';
import { getLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import React from 'react';
import { Field } from 'react-final-form';

const overlineAndSubtitleCondition = ({ cardGroupType, size }) => {
  if (size === 'extraSmall') {
    return false;
  }
  if (
    size === 'small'
    && (cardGroupType === 'three' || cardGroupType === 'four')
  ) {
    return false;
  }
  return true;
};

const careNearMeCondition = ({ cardGroupType }) => cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME;

const cycleCardCondition = ({ cardGroupType }) => cardGroupType === CARD_GROUP_TYPES.CYCLE;

export const I18N_FIELDS = [
  {
    key: 'accessibilityText',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'accessibilityTextEn',
    label: 'Accessibility Description',
    charLimit: 120,
    condition: cycleCardCondition,
  },
  {
    key: 'accessibilityText',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'accessibilityTextEs',
    label: 'Accessibility Description (Spanish)',
    charLimit: 120,
    condition: cycleCardCondition,
  },
  {
    key: 'buttonText',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'buttonTextEn',
    label: 'Button',
    charLimit: 15,
    condition: careNearMeCondition,
  },
  {
    key: 'buttonText',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'buttonTextEs',
    label: 'Button (Spanish)',
    charLimit: 15,
    condition: careNearMeCondition,
  },
  {
    key: 'overline',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'overlineEn',
    label: 'Pre-title',
    charLimit: 40,
    condition: overlineAndSubtitleCondition,
  },
  {
    key: 'overline',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'overlineEs',
    label: 'Pre-title (Spanish)',
    charLimit: 40,
    condition: overlineAndSubtitleCondition,
  },
  {
    key: 'customLogicTitle',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'customLogicTitleEn',
    label: 'Custom Logic Title',
    condition: careNearMeCondition,
  },
  {
    key: 'customLogicTitle',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'customLogicTitleEs',
    label: 'Custom Logic Title (Spanish)',
    condition: careNearMeCondition,
  },
  {
    key: 'title',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'titleEn',
    label: 'Title',
    required: true,
    charLimit: 40,
  },
  {
    key: 'title',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'titleEs',
    label: 'Title (Spanish)',
    charLimit: 40,
  },
  {
    key: 'subtitle',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'subtitleEn',
    label: 'Subtitle',
    charLimit: 40,
    condition: overlineAndSubtitleCondition,
  },
  {
    key: 'subtitle',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'subtitleEs',
    label: 'Subtitle (Spanish)',
    charLimit: 40,
    condition: overlineAndSubtitleCondition,
  },
  {
    key: 'subtitle2',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'subtitle2En',
    label: 'Subtitle',
    charLimit: 40,
    condition: careNearMeCondition,
  },
  {
    key: 'subtitle2',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'subtitle2Es',
    label: 'Subtitle (Spanish)',
    charLimit: 40,
    condition: careNearMeCondition,
  },
  {
    key: 'waittime',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'waittimeEn',
    label: 'Wait Time Label',
    charLimit: 10,
    required: true,
    forWaitTimes: true,
  },
  {
    key: 'waittime',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'waittimeEs',
    label: 'Wait Time Label (Spanish)',
    charLimit: 10,
    forWaitTimes: true,
  },
];

export const QUICKLINK_NAME_FIELDS = [
  {
    key: 'name',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'nameEn',
    label: 'Quicklink Name',
    required: true,
    charLimit: 30,
  },
  {
    key: 'name',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'nameEs',
    label: 'Quicklink Name (Spanish)',
    charLimit: 30,
  },
];

export const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => value === is ? children : null}
  </Field>
);

export const isValidDate = (value) => value && !isNaN(value.getTime()) && !isPastDate(value);

export const getDateHelperText = (value) => {
  if (value) {
    if (isValidDate(value)) {
      return null;
    }

    if (isPastDate(value)) {
      return 'Date cannot be in the past';
    }

    return 'Invalid date format';
  }

  return 'Date is required';
};

export const getCategoryType = (locations) => {
  if (locations && Object.keys(locations).length) {
    if (locations.allUrgentCare) {
      return 'allUrgentCare';
    }

    if (locations.allEmergencyDepartments) {
      return 'allEmergencyDepartments';
    }

    return 'custom';
  }

  return '';
};

export const renderFields = ({
                               cardGroupType,
                               sx,
                               fields = I18N_FIELDS,
                               hasWaitTimes = false,
                               languages = [],
                               size,
                             }) => fields
    .map(
      (
        {
          langKey,
          lang,
          label,
          charLimit,
          disabled = false,
          placeholder,
          required,
          forWaitTimes,
          condition,
        },
        i,
      ) => {
        if (condition && !condition({ cardGroupType, size })) {
          return null;
        }

        if (languages.includes(lang)) {
          if (forWaitTimes && !hasWaitTimes) {
            return null;
          }
          return (
            <Grid item xs={12} key={`field_${i}`} sx={sx.field}>
              <Field name={langKey}>
                {({ input, meta }) => (
                  <TextField
                    label={
                      required ? (
                        <span>
                          <Asterisk /> {label}
                        </span>
                      )
                        : label

                    }
                    charLimit={charLimit}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    placeholder={placeholder ?? label}
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                    disabled={disabled}
                  />
                )}
              </Field>
            </Grid>
          );
        }

        return null;
      },
    )
    .filter((l) => l);

export const getActionLink = (values) => {
  let tempLink = {
    name: [
      { lang: LANGUAGE_CODES.ENGLISH, label: 'action' },
      values.langES ? { lang: LANGUAGE_CODES.SPANISH, label: 'acion' } : null,
    ].filter((notFalse) => notFalse),
  };

  switch (values.linkType) {
    case 'category':
      tempLink = {
        ...tempLink,
        type: 'category',
        data: {
          id: values.categories.map((c) => c.id),
        },
      };
      break;
    case 'in_app':
      tempLink = {
        ...tempLink,
        type: 'in_app',
        data: {
          id: values.inAppDest,
        },
      };
      break;
    case 'list':
      tempLink = {
        ...tempLink,
        type: 'list',
        data: {
          id: values.listDest,
        },
      };
      break;
    case 'external_app':
      if (
        !values.externalType
        && !values.externalURL
        && !values.externalAndroidURL
        && !values.externaliOSURL
      ) {
        return null;
      }
      tempLink = {
        ...tempLink,
        type: 'external_app',
        data: {
          client: values.externalType ? values.externalType : '',
          url: values.externalURL ? values.externalURL : '',
          androidUrl: values.externalAndroidURL
            ? values.externalAndroidURL
            : '',
          iosUrl: values.externaliOSURL ? values.externaliOSURL : '',
        },
      };

      if (values.hasPlatformSpecificFallbacks) {
        tempLink.data.androidFallbackUrl = values.fallbackAndroidURL ?? null;
        tempLink.data.iosFallbackUrl = values.fallbackiOSURL ?? null;
      } else {
        tempLink.data.androidFallbackUrl = null;
        tempLink.data.iosFallbackUrl = null;
      }
      break;
    case 'website':
      if (!values.webType && !values.webUrlEn) {
        return null;
      }
      tempLink = {
        ...tempLink,
        type: 'website',
        data: {
          client: values.webType ?? '',
          url: {
            en: values.webUrlEn ?? '',
            ...values.langES && {
              es: values.webUrlEs ?? '',
            },
          },
        },
      };
      break;
    case 'custom':
      if (!values.custom) {
        return null;
      }
      tempLink = {
        ...tempLink,
        type: 'custom',
        data: {
          url: values.custom,
        },
      };
      break;
    default:
      tempLink = null;
      break;
  }

  return tempLink;
};

export const buildIconUrl = ({ iconImage, size }) => {
  if (iconImage) {
    return iconImage.secureUrl;
  }

  if (size === 'extraSmall') {
    const url = window.location;
    return `${url.protocol}//${url.host}/${
      url.pathname.split('/')[0]
    }icon/favicon-196x196.png`;
  }

  return null;
};

const filterValuesForQuickLinks = (
  values = {},
  quickLinkKeys = [],
  languages = [],
) => {
  const map = { title: [], quickLinksData: [] };
  quickLinkKeys.forEach((key) => {
    switch (key) {
      case 'qlTitleEn':
        map.title.push({
          lang: LANGUAGE_CODES.ENGLISH,
          label: values[key] || '',
        });
        break;
      case 'qlTitleEs':
        if (hasSpanish(languages)) {
          map.title.push({
            lang: LANGUAGE_CODES.SPANISH,
            label: values[key] || '',
          });
        }
        break;
      case 'qlBackgroundColor':
        map.backgroundColor = values[key];
        break;
      case 'qlTextColor':
        map.textColor = values[key];
        break;
      case 'qlVisibilityRule':
        map.visibilityRule = values[key];
        break;
      case 'qlQuickLinks':
        map.quickLinksData = values[key].map(
          ({ id, name, iconUrl, iconTint, url, type, data }) => ({
            id,
            name,
            iconUrl,
            iconTint,
            url,
            type,
            data,
          }),
        );
        break;
      default:
        break;
    }
  });
  return map;
};

export const processUpdatedI18NData = ({ fieldName, lang, value, updatedData }) => {
  if (fieldName === 'waittime') {
    if (value) {
      updatedData.waitTimes.label.push({ lang, label: value });
    }
  } else if (value) {
    if (!updatedData[fieldName]) {
      updatedData[fieldName] = [];
    }
    updatedData[fieldName].push({ lang, label: value });
  } else if (updatedData[fieldName]) {
    updatedData[fieldName] = updatedData[fieldName].filter(
      (l) => l.lang !== lang,
    );
  } else {
    updatedData[fieldName] = [];
  }
};

export const buildCardDataForUpdate = ({
                                         cardGroupType,
                                         cardGroupId,
                                         cardId,
                                         backgroundImage,
                                         customLocations,
                                         hasWaitTimes,
                                         iconImage,
                                         initialValues = {},
                                         languages = [],
                                         quickLinks,
                                         size,
                                         useColorFillAsBackground,
                                         values,
                                         waitTimes,
                                       }) => {
  const updatedData = { cardGroupId, cardId };

  if (hasWaitTimes) {
    updatedData.waitTimes = {
      included: true,
      label: [],
      showWhenCollapsed: waitTimes.showWhenCollapsed,
    };
  }

  const unprocessedValues = {};
  Object.keys(initialValues).forEach((key) => (unprocessedValues[key] = false));

  Object.keys(values).forEach((key) => {
    delete unprocessedValues[key];
    const index = key.length - 2;
    const fieldName = key.substring(0, index);
    const isI18NField = !!I18N_FIELDS.find((f) => f.key === fieldName);
    const value = values[key];
    if (isI18NField) {
      const lang = key.substring(index).toLowerCase();
      processUpdatedI18NData({ fieldName, lang, value, updatedData });
    } else {
      let updatedActionLink;
      switch (key) {
        case 'cardGroupType':
          // Ignore
          break;
        case 'backgroundImageUrl':
          if (backgroundImage) {
            updatedData.backgroundImageUrl = backgroundImage.secureUrl;
          }
          break;
        case 'categoryType':
          switch (value) {
            case 'allUrgentCare':
              updatedData.locations = {
                allUrgentCare: true,
                allEmergencyDepartments: false,
              };
              break;
            case 'allEmergencyDepartments':
              updatedData.locations = {
                allUrgentCare: false,
                allEmergencyDepartments: true,
              };
              break;
            case 'custom':
              updatedData.locations = {
                allUrgentCare: false,
                allEmergencyDepartments: false,
                custom: {
                  sites: [],
                  places: [],
                  buildings: [],
                },
              };
              customLocations?.forEach((location) => {
                switch (location.type) {
                  case 'site':
                    updatedData.locations.custom.sites.push(location.id);
                    break;
                  case 'place':
                    updatedData.locations.custom.places.push(location.id);
                    break;
                  case 'building':
                    updatedData.locations.custom.buildings.push(location.id);
                    break;
                  default:
                    // ignore
                    break;
                }
              });
              break;
            default:
              break;
          }
          break;
        case 'expires':
          if (values.expiresFlag) {
            updatedData.expires = new Date(
              Date.UTC(
                value.getUTCFullYear(),
                value.getUTCMonth(),
                value.getUTCDate(),
                value.getUTCHours(),
                value.getUTCMinutes(),
                value.getUTCSeconds(),
              ),
            );
          } else {
            updatedData.expires = null;
          }
          break;
        case 'expiresFlag':
          break;
        case 'iconUrl':
          if (cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME) {
            if (
              values.displayType === A2C_DISPLAY_TYPES.MAP
              || values.displayType === A2C_DISPLAY_TYPES.MAP_AND_IMAGE
            ) {
              updatedData.iconUrl = null;
              break;
            }
          }
          updatedData.iconUrl = iconImage
            ? buildIconUrl({ iconImage, size })
            : value;
          break;
        case 'iconUrl2': // A2C: Map & Image
          if (cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME) {
            if (
              values.displayType === A2C_DISPLAY_TYPES.MAP
              || values.displayType === A2C_DISPLAY_TYPES.IMAGE
            ) {
              updatedData.iconUrl2 = null;
              break;
            }
          }
          updatedData.iconUrl2 = iconImage
            ? buildIconUrl({ iconImage, size })
            : value;
          break;
        case 'linkType':
          updatedActionLink = getActionLink(values);
          updatedData.linkData = updatedActionLink;
          break;
        case 'locationType':
          if (cardGroupType === CARD_GROUP_TYPES.EXPANDABLE) {
            if (!updatedData.locations) {
              updatedData.locations = {};
            }
            switch (value) {
              case 'custom':
                updatedData.locations.custom = {
                  sites: [],
                  places: [],
                  buildings: [],
                };
                break;
              default:
                updatedData.locations[key] = true;
                break;
            }
          }
          break;
        case 'measurementHeight':
          updatedData.measurements = {
            height: value === 'minimum' ? null : value,
          };
          break;
        case 'linkBackgroundColor':
        case 'qlTitleEn':
        case 'qlTitleEs':
        case 'qlBackgroundColor':
        case 'qlTextColor':
        case 'qlVisibilityRule':
        case 'qlQuickLinks':
          // Ignore Quick Links values
          break;
        case 'waitTimesIncluded':
          break;
        case 'waitTimesShowWhenCollapsed':
          if (hasWaitTimes) {
            updatedData.waitTimes.showWhenCollapsed = value;
          }
          break;
        case 'actionLink':
        case 'categories':
        case 'custom':
        case 'customLocation':
        case 'externalAndroidURL':
        case 'externaliOSURL':
        case 'hasPlatformSpecificFallbacks':
        case 'fallbackAndroidURL':
        case 'fallbackiOSURL':
        case 'externalType':
        case 'externalURL':
        case 'inAppDest':
        case 'langES':
        case 'listDest':
        case 'webUrlEn':
        case 'webUrlEs':
        case 'webType':
          // Ignore Quicklink fields
          break;
        default:
          updatedData[key] = value;
          break;
      }
    }
  });

  if (!values.expiresFlag) {
    updatedData.expires = null;
  }

  if (useColorFillAsBackground) {
    updatedData.backgroundImageUrl = '';
  } else {
    updatedData.backgroundColor = DEFAULT_BACKGROUND_COLOR;
  }

  if (
    !(
      cardGroupType === CARD_GROUP_TYPES.ONE
      || cardGroupType === CARD_GROUP_TYPES.TEMPORARY
      || cardGroupType === CARD_GROUP_TYPES.MYCHART
    )
  ) {
    delete updatedData.textAlignment;
  }

  if (cardGroupType !== CARD_GROUP_TYPES.EXPANDABLE) {
    delete updatedData.locations;
  }

  if (cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME) {
    if (!updatedData.buttonText) {
      updatedData.buttonText = [];
    }
  }

  if (!updatedData.overline) {
    updatedData.overline = [];
  }

  if (!updatedData.subtitle) {
    updatedData.subtitle = [];
  }

  if (!updatedData.visibilityRule) {
    updatedData.visibilityRule = null;
  }

  if (quickLinks) {
    if (cardGroupType === CARD_GROUP_TYPES.MYCHART) {
      if (!updatedData.myChartUrl) {
        updatedData.myChartUrl = null;
      }

      const quickLinksData = buildCardDataForUpdate({
        cardGroupType,
        cardGroupId,
        cardId: quickLinks.id,
        backgroundImage,
        iconImage,
        size,
        useColorFillAsBackground: true,
        values: filterValuesForQuickLinks(
          values,
          [
            'qlTitleEn',
            'qlTitleEs',
            'qlBackgroundColor',
            'qlTextColor',
            'qlQuickLinks',
            'qlVisibilityRule',
          ],
          languages,
        ),
      });

      const loginCardId = updatedData.cardId;
      const quicklinksCardId = quickLinksData.cardId;

      // These attributes are no longer supported in the update call
      delete updatedData.cardId;
      delete updatedData.cardGroupId;
      delete quickLinksData.cardId;
      delete quickLinksData.cardGroupId;
      delete updatedData.sortingOptionsType;
      delete updatedData.customLogicTitle;
      delete unprocessedValues.customLogicTitle;

      return {
        loginCardId,
        login: updatedData,
        quicklinksCardId,
        quicklinks: quickLinksData,
      };
    }

    if (
      cardGroupType === CARD_GROUP_TYPES.QUICKLINK_LIST
      || cardGroupType === CARD_GROUP_TYPES.FINDING_CARE
      || cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME
    ) {
      delete updatedData.myChartUrl;

      const cardData = buildCardDataForUpdate({
        cardGroupType,
        cardGroupId,
        cardId: quickLinks.id,
        iconImage,
        size,
        values: filterValuesForQuickLinks(
          values,
          ['qlTitleEn', 'qlTitleEs', 'qlQuickLinks', 'qlVisibilityRule'],
          languages,
        ),
      });

      updatedData.quickLinksData = cardData.quickLinksData || [];
      if (
        cardGroupType === CARD_GROUP_TYPES.FINDING_CARE
        && values.linkBackgroundColor
      ) {
        updatedData.quickLinksData = updatedData.quickLinksData.map((ql) => ({
          ...ql,
          iconTint: values.linkBackgroundColor,
        }));
      }
      delete updatedData.linkBackgroundColor;
    }
  }

  if (careNearMeCondition({ cardGroupType })) {
    updatedData.sortingOptions = {
      type: updatedData.sortingOptionsType,
      title: updatedData.customLogicTitle,
    };
  }
  delete updatedData.sortingOptionsType;
  delete updatedData.customLogicTitle;
  delete unprocessedValues.customLogicTitle;

  Object.keys(unprocessedValues).forEach((key) => {
    const i18nFieldData = I18N_FIELDS.find((item) => item.langKey === key);
    if (i18nFieldData) {
      processUpdatedI18NData({
        fieldName: i18nFieldData.key,
        lang: i18nFieldData.lang,
        value: null,
        updatedData,
      });
    }
  });

  return updatedData;
};

export const getCardSizeFromMeasurementHeight = (measurementHeight) => {
  switch (measurementHeight) {
    case 'minimum':
    case 'short':
      return 'small';
    case 'medium':
      return 'medium';
    case 'tall':
      return 'large';
    default:
      break;
  }

  return null;
};

export const mapEditCardToPreview = ({
                                       cardKey,
                                       languages = [],
                                       selectedCard,
                                       values,
                                     }) => {
  const card = {
    validation: selectedCard.validation,
    measurements: { height: values.measurementHeight },
    size: getCardSizeFromMeasurementHeight(values.measurementHeight),
  };
  if (selectedCard.waitTimes) {
    card.waitTimes = {
      included: values.waitTimesIncluded,
      label: [],
      showWhenCollapsed: values.waitTimesShowWhenCollapsed,
    };
  }
  I18N_FIELDS.forEach(({ key, lang, langKey }) => {
    if (key === 'waittime') {
      if (card?.waitTimes?.label) {
        card.waitTimes.label.push({ lang, label: values[langKey] || '' });
      }
    } else {
      if (!card.hasOwnProperty(key)) {
        card[key] = [];
      }

      if (languages.includes(lang)) {
        card[key].push({ lang, label: values[langKey] || '' });
      }
    }
  });
  for (const key in selectedCard) {
    if (!card.hasOwnProperty(key)) {
      card[key] = values[key];
    }
  }

  if (values.qlQuickLinks) {
    card.quickLinksData = values.qlQuickLinks;
  }

  return {
    cardGroupId: selectedCard.cardGroupId,
    cardGroupType: selectedCard.cardGroupType,
    cardId: selectedCard.cardId,
    validation: selectedCard.validation,
    [cardKey]: card,
  };
};

export const mapMyChartEditCardToPreview = ({
                                              languages = [],
                                              selectedCard,
                                              values,
                                            }) => {
  const updatedLoginValues = {
    backgroundColor: values.backgroundColor,
    iconUrl: values.iconUrl,
    textAlignment: values.textAlignment,
    textColor: values.textColor,
    measurements: { height: values.measurementHeight },
    size: getCardSizeFromMeasurementHeight(values.measurementHeight),
    ...buildI18NValues(I18N_FIELDS, languages, values),
  };
  const updatedQuickLinksValues = filterValuesForQuickLinks(
    values,
    [
      'qlTitleEn',
      'qlTitleEs',
      'qlBackgroundColor',
      'qlTextColor',
      'qlQuickLinks',
      'qlVisibilityRule',
    ],
    languages,
  );
  return {
    card1: {
      ...selectedCard,
      ...updatedLoginValues,
    },
    card2: { ...selectedCard.quickLinks, ...updatedQuickLinksValues },
  };
};

const sections = {
  GENERAL: 'General Tab',
  SORTING_OPTIONS: 'Sorting Options',
  LINK: 'Quicklink Tab',
  DISPLAY: 'Display Tab',
  MYCHART_LOGIN: 'Login Card',
  MYCHART_QUICKLINKS: 'MyChart Quicklinks Card',
  MY_FAVORITES_EMPTY: 'Empty State',
  MY_FAVORITES_ACTIVE: 'Active State',
};
Object.freeze(sections);

export const getErrorFormFieldMap = (isMyChart = false) => {
  const generalOrMyChartLoginSection = isMyChart
    ? sections.MYCHART_LOGIN
    : sections.GENERAL;
  const displayOrMyChartLoginSection = isMyChart
    ? sections.MYCHART_LOGIN
    : sections.DISPLAY;
  return new Map([
    /* Sorting Options */
    [
      'customLogicTitleEn',
      { label: 'Custom Logic Title', section: sections.SORTING_OPTIONS },
    ],
    /* General or MyChart Login */
    [
      'overlineEn',
      { label: 'Pre-title', section: generalOrMyChartLoginSection },
    ],
    [
      'overlineEs',
      { label: 'Pre-title (Spanish)', section: generalOrMyChartLoginSection },
    ],
    [
      'subtitleEn',
      { label: 'Subtitle', section: generalOrMyChartLoginSection },
    ],
    [
      'subtitleEs',
      { label: 'Subtitle (Spanish)', section: generalOrMyChartLoginSection },
    ],
    ['titleEn', { label: 'Title', section: generalOrMyChartLoginSection }],
    [
      'titleEs',
      { label: 'Title (Spanish)', section: generalOrMyChartLoginSection },
    ],
    ['waittimeEn', { label: 'Wait Time Label', section: sections.GENERAL }],
    [
      'waittimeEs',
      { label: 'Wait Time Label (Spanish)', section: sections.GENERAL },
    ],
    ['expires', { label: 'Expires On', section: sections.GENERAL }],
    [
      'visibilityRule',
      { label: 'Visibility Rule', section: generalOrMyChartLoginSection },
    ],

    /* Link */
    ['externaliOSURL', { label: 'External iOS URL', section: sections.LINK }],
    [
      'externalAndroidURL',
      { label: 'External Android URL', section: sections.LINK },
    ],
    ['fallbackiOSURL', { label: 'Fallback iOS URL', section: sections.LINK }],
    [
      'fallbackAndroidURL',
      { label: 'Fallback Android URL', section: sections.LINK },
    ],
    ['externalURL', { label: 'Fallback URL', section: sections.LINK }],
    ['webUrlEn', { label: 'Website URL', section: sections.LINK }],
    ['webUrlEs', { label: 'Website URL (Spanish)', section: sections.LINK }],
    ['custom', { label: 'Open Field', section: sections.LINK }],

    /* Display */
    [
      'backgroundColor',
      { label: 'Background Color', section: displayOrMyChartLoginSection },
    ],
    [
      'textColor',
      { label: 'Text Color', section: displayOrMyChartLoginSection },
    ],

    /* MyChart Login */
    ['myChartUrl', { label: 'MyChart URL', section: sections.MYCHART_LOGIN }],

    /* MyChart QuickLinks */
    ['qlTitleEn', { label: 'Title', section: sections.MYCHART_QUICKLINKS }],
    [
      'qlTitleEs',
      { label: 'Title (Spanish)', section: sections.MYCHART_QUICKLINKS },
    ],
    [
      'qlBackgroundColor',
      { label: 'Background Color', section: sections.MYCHART_QUICKLINKS },
    ],
    [
      'qlTextColor',
      { label: 'Text Color', section: sections.MYCHART_QUICKLINKS },
    ],
    [
      'qlVisibilityRule',
      { label: 'Visibility Rule', section: sections.MYCHART_QUICKLINKS },
    ],

    /* My Favorites */
    [
      'empty_displayType',
      { label: 'Display Type', section: sections.MY_FAVORITES_EMPTY },
    ],
    ['empty_titleEn', { label: 'Title', section: sections.MY_FAVORITES_EMPTY }],
    [
      'empty_titleEs',
      { label: 'Title (Spanish)', section: sections.MY_FAVORITES_EMPTY },
    ],
    [
      'empty_subtitleEn',
      { label: 'Subtitle', section: sections.MY_FAVORITES_EMPTY },
    ],
    [
      'empty_subtitleEs',
      { label: 'Subtitle (Spanish)', section: sections.MY_FAVORITES_EMPTY },
    ],
    [
      'empty_buttonTextEn',
      { label: 'Button Text', section: sections.MY_FAVORITES_EMPTY },
    ],
    [
      'empty_buttonTextEs',
      { label: 'Button Text (Spanish)', section: sections.MY_FAVORITES_EMPTY },
    ],
    [
      'empty_iconUrl',
      { label: 'Support Image', section: sections.MY_FAVORITES_EMPTY },
    ],
    [
      'empty_backgroundColor',
      { label: 'Background Color', section: sections.MY_FAVORITES_EMPTY },
    ],
    [
      'empty_textColor',
      { label: 'Text Color', section: sections.MY_FAVORITES_EMPTY },
    ],
    [
      'empty_visibilityRule',
      { label: 'Visibility Rule', section: sections.MY_FAVORITES_EMPTY },
    ],

    [
      'active_titleEn',
      { label: 'Title', section: sections.MY_FAVORITES_ACTIVE },
    ],
    [
      'active_titleEs',
      { label: 'Title (Spanish)', section: sections.MY_FAVORITES_ACTIVE },
    ],
    [
      'active_backgroundColor',
      { label: 'Background Color', section: sections.MY_FAVORITES_ACTIVE },
    ],
    [
      'active_textColor',
      { label: 'Text Color', section: sections.MY_FAVORITES_ACTIVE },
    ],
    [
      'active_visibilityRule',
      { label: 'Visibility Rule', section: sections.MY_FAVORITES_ACTIVE },
    ],

    /* Open Platform */
    ['cardName', { label: 'Title', section: sections.GENERAL }],
    ['moduleId', { label: 'Module ID', section: sections.GENERAL }],
  ]);
};

export const SUPPORTED_LINK_TYPES = [
  'category',
  'external_app',
  'in_app',
  'list',
  'website',
  'custom',
];

export const isValidJSON = (val) => {
  if (!val) {
    return true;
  }
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
};

const getSecondDataLine = (item) => {
  if (
    !item?.parentSite?.name
    || (!item?.parentBuilding?.name && !item?.parentFloor?.name)
  ) return null;
  const labels = [];
  if (item?.parentSite?.name) labels.push(`${getLabelByLang(item.parentSite.name)}`);
  if (item?.parentBuilding?.name) labels.push(`${getLabelByLang(item.parentBuilding.name)}`);
  if (item?.parentFloor?.name) labels.push(`${getLabelByLang(item.parentFloor.name)}`);
  return labels.join(', ');
};

const getThirdDataLine = (item) => {
  if (
    !item?.geoLocation?.address?.street
    && !item?.geoLocation?.address?.state
    && !item?.geoLocation?.address?.city
    && !item?.geoLocation?.address?.zip
  ) return null;

  const labels = [];
  if (item?.geoLocation?.address?.street) labels.push(item.geoLocation.address.street);
  if (item?.geoLocation?.address?.city) labels.push(item.geoLocation.address.city);
  if (item?.geoLocation?.address?.state) labels.push(item.geoLocation.address.state);
  let retVal = labels.join(', ');
  if (item?.geoLocation?.address?.zip) retVal = `${retVal} ${item.geoLocation.address.zip}`;
  return retVal;
};

export const mapCustomLocations = ({
                                     sites = [],
                                     places = [],
                                     buildings = [],
                                   }) => {
  const list = [];
  sites.forEach((site) => {
    list.push({
      id: site.id,
      name: site.name,
      type: 'site',
      details: getThirdDataLine(site),
    });
  });

  places.forEach((place) => {
    list.push({
      id: place.id,
      name: place.name,
      details: getSecondDataLine(place),
      details2: getThirdDataLine(place),
      type: 'place',
    });
  });

  buildings.forEach((building) => {
    list.push({
      id: building.id,
      siteId: building.parentSite.id,
      buildingExternalId: building.id,
      name: building.name,
      details: getLanguageLabel(building.parentSite.name),
      details2: getThirdDataLine(building),
      type: 'building',
    });
  });

  return list;
};
