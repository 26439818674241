import { FormatAlignCenter, FormatAlignLeft } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import Asterisk from 'components/asterisk/asterisk';
import FillOptions from 'components/forms/fillOptions';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import TabPanel from 'components/tabPanel/tabPanel';
import { getFormLabel } from 'helpers/form-util';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { TextField as FinalTextField } from 'mui-rff';
import { renderFields } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

export const MyChartLoginTabPanel = ({
                                       backgroundColor,
                                       sx,
                                       formValues,
                                       index,
                                       languages = [],
                                       onChange: onFormChange,
                                       onIconUploaded,
                                       size,
                                       tabValue,
                                       textColor,
                                       onBackgroundImageFileUploaded,
                                       setUseColorFillAsBackground,
                                       useColorFillAsBackground,
                                       form,
                                     }) => {
  const isUberAdmin = useCheckGozioAdmin();

  return (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      <Box sx={sx.fieldArea}>
        <Grid item xs>
          <Typography sx={sx.displayHeading} variant="subtitle1">
            General
          </Typography>
        </Grid>
        <Grid item xs sx={sx.formFields}>
          {renderFields({ sx: sx, languages, size, values: formValues })}
        </Grid>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            {/* The open field is visible, but disabled for network admins. */}
            <Typography sx={sx.displayHeading} variant="subtitle1">
              MyChart URL
            </Typography>
          </Grid>
          <Grid item xs>
            <FinalTextField
              label={getFormLabel('Open Field', true)}
              name="myChartUrl"
              type="text"
              variant="outlined"
              disabled={!isUberAdmin}
            />
          </Grid>
        </Box>
        {isUberAdmin && (
          <Box sx={sx.fieldSection}>
            <Grid item xs>
              {/* The JSON input is only visible to gozio admins. */}
              <Typography sx={sx.displayHeading} variant="subtitle1">
                Visibility Rule
              </Typography>
            </Grid>
            <Grid item xs>
              <FinalTextField
                label={getFormLabel('JSON Input')}
                multiline
                name="visibilityRule"
                variant="outlined"
                minRows={7}
                maxRows={7}
                sx={{ fontFamily: 'monospace' }}
              />
            </Grid>
          </Box>
        )}
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <FillOptions
              textColor={textColor}
              backgroundColor={backgroundColor}
              onBackgroundColorUpdated={(color) => {
                form.change('backgroundImageUrl', null);
                onFormChange();
                setUseColorFillAsBackground(true);
              }}
              onImageUpdated={(imageData) => {
                setUseColorFillAsBackground(false);
                onBackgroundImageFileUploaded(imageData);
                onFormChange();
              }}
              onRadioChange={(newValue) => {
                if (newValue === 'color') {
                  setUseColorFillAsBackground(true);
                  form.change('backgroundImageUrl', null);
                  onFormChange();
                } else {
                  setUseColorFillAsBackground(false);
                  onFormChange();
                }
              }}
              initialOption={!useColorFillAsBackground ? 'image' : 'color'}
            />
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Typography sx={sx.displayHeading} variant="subtitle1">
              Template Options
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography sx={sx.displaySubheading} variant="body1">
              Text Alignment
            </Typography>
          </Grid>
          <Grid item xs sx={sx.field}>
            <Field name="textAlignment">
              {({ input: { onChange, value } }) => [
                <FormatAlignLeft
                  key="textAlignmentLeft"
                  name="textAlignment"
                  onClick={() => {
                    onChange('left');
                    onFormChange();
                  }}
                  sx={{
                    ...sx.alignmentIcon,
                    ...value === 'left' && sx.selectedAlignmentIcon,
                    borderBottomLeftRadius: '4px',
                    borderTopLeftRadius: '4px',
                    borderRight: 'none',
                  }}
                />,
                <FormatAlignCenter
                  key="textAlignmentCenter"
                  name="textAlignment"
                  onClick={() => {
                    onChange('center');
                    onFormChange();
                  }}
                  sx={{
                    ...sx.alignmentIcon,
                    ...value === 'center' && sx.selectedAlignmentIcon,
                    borderBottomRightRadius: '4px',
                    borderTopRightRadius: '4px',
                    borderLeft: 'none',
                  }}
                />,
              ]}
            </Field>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Typography sx={sx.displaySubheading} variant="body1">
              Minimum Card Height
            </Typography>
          </Grid>
          <Grid item xs sx={sx.radioContainer}>
            <Field name="measurementHeight">
              {({ input: { value, onChange } }) => (
                <GozioRadioGroup
                  groupName="measurementHeight"
                  onChange={onChange}
                  options={[
                    { label: 'Minimum', value: 'minimum' },
                    { label: 'Short', value: 'short' },
                    { label: 'Medium', value: 'medium' },
                    { label: 'Tall', value: 'tall' },
                  ]}
                  row={true}
                  value={value}
                />
              )}
            </Field>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Typography sx={sx.displayHeading} variant="subtitle1">
              Card Icon
            </Typography>
          </Grid>
          <Field name="iconUrl">
            {({ input: { onChange, value } }) => (
              <ImageUpLoader
                title={
                  <span>
                    <Asterisk /> Supporting Icon
                  </span>
                }
                image={value ? { url: value } : null}
                fileInfo="Supported files includes jpeg and png. File size should be no larger than 50KB. Image dimensions must be at least 120px by 120px."
                onImageUpdated={({ file, url }) => {
                  onIconUploaded({ file, iconUrl: url });
                  onChange(url);
                }}
                maxFileSize={50000}
                minHeight={120}
                minWidth={120}
                allowDelete={false}
                showImage={true}
                previewWidth={64}
                previewHeight={64}
              />
            )}
          </Field>
        </Box>
      </Box>
    </TabPanel>
  );
};

MyChartLoginTabPanel.propTypes = {
  sx: PropTypes.object,
  backgroundColor: PropTypes.string,
  form: PropTypes.object,
  formValues: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number,
  onBackgroundImageFileUploaded: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onIconUploaded: PropTypes.func.isRequired,
  setUseColorFillAsBackground: PropTypes.func.isRequired,
  useColorFillAsBackground: PropTypes.bool,
  size: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
    'large',
    'extraLarge',
  ]).isRequired,
  tabValue: PropTypes.number,
  textColor: PropTypes.string,
};

MyChartLoginTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  tabValue: 0,
};
