import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Add, AddCircle, Delete, Edit } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import EmptySection from 'components/emptySection/emptySection';
import GozioListItem from 'components/listItem/gozioListItem';
import Asterisk from 'components/asterisk/asterisk';
import ColorOptions from 'components/colorOptions/colorOptions';
import TabPanel from 'components/tabPanel/tabPanel';
import TextField from 'components/textField/textField';
import { buildDashboardEditStyles } from 'pages/mobileSettings/dashboard/containers/editCard/dashboardEditStyles';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { getLabelByLang } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import Condition from 'components/forms/condition';
import CycleCardStepperModal from './cycleCardStepperModal';
import { buildDashboardCardInitializeValues } from '../../../../../components/dashboard/dashboardHelper';
import VisibilitySelector from '../../../../../components/selector/VisibilitySelector';
import YesNoRadioGroup from '../../../../../components/radioGroup/yesNoRadioGroup';

const CycleCardGroupTabPanel = ({
                                  form,
                                  formValues,
                                  handleCardReorder,
                                  handleGroupCardSaved,
                                  onDeleteCard,
                                  setDashboardRefreshRequired,
                                  languages = [],
                                }) => {
  const theme = useTheme();
  const styles = buildDashboardEditStyles({ theme });

  const MAX_CYCLE_CARDS = 6;

  const cardsLength = formValues?.cards?.length || 0;
  const [selectedCycleCard, setSelectedCycleCard] = useState(null);

  return (
    <TabPanel sx={{ ...styles.tabPanel, padding: '24px 24px 0' }} value={0} index={0}>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12}>
          <Field name="groupTitleEn">
            {({ input, meta }) => (
              <TextField
                label={
                  <span>
                    <Asterisk/> Card Group Name
                  </span>
                }
                charLimit={40}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                placeholder="Card Group Name"
                value={input.value}
                onChange={input.onChange}
                fullWidth
              />
            )}
          </Field>
          <Condition when="langES" is={true}>
            <Grid item xs={12} marginTop={'16px'}>
              <Field name="groupTitleEs">
                {({ input, meta }) => (
                  <TextField
                    label={<span>Card Group Name (Spanish)</span>}
                    charLimit={40}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    placeholder="Card Group Name"
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                  />
                )}
              </Field>
            </Grid>
          </Condition>
        </Grid>
      </Box>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12}>
          <Typography sx={styles.displayHeading} variant="subtitle1">
            Display Options
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...styles.displaySubheading, marginTop: '16px' }} variant="subtitle2">
            Display Group Name?
          </Typography>
          <YesNoRadioGroup fieldName={'showGroupTitle'}/>
        </Grid>
        <Condition when="showGroupTitle" is={true}>
          <Grid item marginTop={'16px'}>
            <Field name="groupTitleTextColor">
              {({ input: { value, onChange } }) => (
                <ColorOptions
                  initialColor={value?.toString()}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Group name color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardTextColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Condition>
        <Grid item xs={12} marginTop={'16px'}>
          <Typography sx={styles.displaySubheading} variant="subtitle2">
            Include buttons in the cards?
          </Typography>
          <YesNoRadioGroup fieldName={'showButtons'}/>
        </Grid>
        <Grid item xs={12} marginTop={'16px'}>
          <Grid item xs={12}>
            <Typography sx={styles.displaySubheading} variant="subtitle2">
              Card Title Text Color
            </Typography>
          </Grid>
          <Grid item xs marginTop={'16px'}>
            <Field name="textColor">
              {({ input: { value, onChange } }) => (
                <ColorOptions
                  initialColor={value?.toString()}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Text color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardTextColor',
                  }}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} marginTop={'16px'}>
            <Typography sx={styles.displaySubheading} variant="subtitle2">
              Card Background Color
            </Typography>
          </Grid>
          <Grid item marginTop={'16px'}>
            <Field name="backgroundColor">
              {({ input: { value, onChange } }) => (
                <ColorOptions
                  initialColor={value?.toString()}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Background color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardBackgroundColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Grid>
        {(<VisibilitySelector/>)}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '14px',
            paddingTop: '5px',
          }}
        >
          <Typography variant="subtitle1" className="required">
            {cardsLength} Cycle Cards
          </Typography>
          {cardsLength > 0 && cardsLength < MAX_CYCLE_CARDS && (
            <IconButton
              onClick={() => {
                const newCycleCard = {
                  cardGroupId: formValues.cardGroupId,
                  cardGroupType: formValues.cardGroupType,
                };
                setSelectedCycleCard(newCycleCard);
              }}
              aria-label="add-tile"
              sx={{ padding: 0 }}
              size="large"
            >
              <AddCircle
                sx={{
                  color: ColorPalette.primary.main,
                  fontSize: '40px',
                }}
              />
            </IconButton>
          )}
        </Box>
        <Grid>
          {cardsLength === 0 ? (
            <EmptySection
              title="There Are No Cycle Cards Set Up"
              buttonLabel="Add a Cycle Card"
              hasPermission={true}
              ButtonIcon={Add}
              buttonClicked={() => {
                const newCycleCard = {
                  cardGroupId: formValues.cardGroupId,
                  cardGroupType: formValues.cardGroupType,
                };
                setSelectedCycleCard(newCycleCard);
              }}
            />
          ) : (
            <GozioListItem
              listHeaderName="Card Title"
              progressHeaderName="Progress"
              enableReorder={true}
              options={formValues.cards.map((card, index) => ({
                id: card.id,
                order: index,
                label: getLabelByLang(card.title),
                validation: card.validation,
                actions: [
                  {
                    Icon: Edit,
                    hover: 'Edit',
                    onClick: (cardId) => {
                      const selectedCard = formValues.cards.find((item) => item.id === cardId);
                      if (selectedCard) {
                        const initializedCard = buildDashboardCardInitializeValues(selectedCard);
                        initializedCard.cardId = selectedCard.id;
                        initializedCard.cardGroupType = formValues.cardGroupType;
                        initializedCard.cardGroupId = formValues.cardGroupId;
                        setSelectedCycleCard(initializedCard);
                      }
                    },
                  },
                  {
                    Icon: Delete,
                    hover: 'Delete',
                    onClick: (cardId) => {
                      if (cardId) {
                        onDeleteCard(cardId, form);
                        setDashboardRefreshRequired(true);
                      }
                    },
                  },
                ],
              }))}
              updateOptions={handleCardReorder}
            />
          )}
        </Grid>
      </Box>
      {selectedCycleCard && (<CycleCardStepperModal
          open={true}
          showButtons={formValues.showButtons}
          languages={languages}
          currentValues={selectedCycleCard}
          onSave={
            (values) => {
              handleGroupCardSaved(values, form);
              setDashboardRefreshRequired(true);
              setSelectedCycleCard(null);
            }
          }
          onClose={() => setSelectedCycleCard(null)}
        />
      )}
    </TabPanel>
  );
};

export default CycleCardGroupTabPanel;
