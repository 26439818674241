import { buildLanguageSchema, LANGUAGE_CODES } from '../../../../../helpers/lang-util';
import { processUpdatedI18NData } from './editCardHelper';
import * as Yup from 'yup';

export const CARD_GROUP_I18N_FIELDS = [
  {
    key: 'groupTitle',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'groupTitleEn',
    label: 'Card Group Title',
    charLimit: 40,
    required: true,
  },
  {
    key: 'groupTitle',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'groupTitleEs',
    label: 'Card Group Title (Spanish)',
    charLimit: 40,
  },
];

export const getErrorFormFieldMap = () => new Map([
  ['groupTitleEn', { label: 'Group Title', section: 'Group Title' }],
  ['groupTitleEs', { label: 'Group Title (Spanish)', section: 'Group Title' }],
  ['showGroupTitle', { label: 'Show Group Title', section: 'Group Title' }],
  ['groupTitleTextColor', { label: 'Group Title Text Color', section: 'Display Options' }],
  ['navlinkDisplay', { label: 'NavLink Display', section: 'NavLink Display Options' }],
  ['navlinkActiveCards', { label: 'Number of NavLink Active Cards', section: 'NavLink Display Options' }],
  ['showButtons', { label: 'Show Buttons', section: 'Display Options' }],
  ['textColor', { label: 'Text Color', section: 'Display Options' }],
  ['backgroundColor', { label: 'Background Color', section: 'Display Options' }],
  ['visibilityRuleSelect', { label: 'Visibility Rule', section: 'Visibility Rule' }],
  ['visibilityRuleCustom', { label: 'Custom Visibility Rule', section: 'Visibility Rule' }],
  ],
);

export const buildCardGroupDataForUpdate = ({
                                              initialValues = {},
                                              values,
                                            }) => {
  const updatedData = {};
  const unprocessedValues = {};
  Object.keys(initialValues).forEach((key) => (unprocessedValues[key] = false));

  Object.keys(values).forEach((key) => {
    delete unprocessedValues[key];
    const index = key.length - 2;
    const fieldName = key.substring(0, index);
    const isI18NField = !!CARD_GROUP_I18N_FIELDS.find((f) => f.key === fieldName);
    const value = values[key];
    if (isI18NField) {
      const lang = key.substring(index).toLowerCase();
      processUpdatedI18NData({ fieldName, lang, value, updatedData });
    } else {
      switch (key) {
        case 'showButtons':
          updatedData.showButtons = value;
          break;
        case 'showGroupTitle':
          updatedData.showGroupTitle = value;
          break;
        case 'groupTitleTextColor':
          updatedData.groupTitleTextColor = value;
          break;
        case 'navlinkDisplay':
          updatedData.navlinkDisplay = value;
          break;
        case 'navlinkActiveCards':
          updatedData.navlinkActiveCards = value;
          break;
        case 'visibilityRuleSelect':
          if (value === 'custom') {
            updatedData.visibilityRule = null;
            updatedData.visibilityRuleCustom = values.visibilityRuleCustom;
          } else if (value === 'allUsers') {
            updatedData.visibilityRuleCustom = null;
            updatedData.visibilityRule = null;
          } else {
            updatedData.visibilityRuleCustom = null;
            updatedData.visibilityRule = values.visibilityRuleSelect;
          }
          break;
        case 'textColor':
          updatedData.textColor = value;
          break;
        case 'backgroundColor':
          updatedData.backgroundColor = value;
          break;
        case 'cards':
          updatedData.cards = value.map((card) => card.id);
          break;
        default:
          break;
      }
    }
  });

  return updatedData;
};

// Maps the edited card values to preview to display on the mobile preview.
export const mapEditCardGroupToPreview = ({
                                            cardGroupId,
                                            languages = [],
                                            selectedCardGroup,
                                            values,
                                          }) => {
  const cardGroup = {};
  CARD_GROUP_I18N_FIELDS.forEach(({ key, lang, langKey }) => {
    if (!cardGroup.hasOwnProperty(key)) {
      cardGroup[key] = [];
    }
    if (languages.includes(lang)) {
      cardGroup[key].push({ lang, label: values[langKey] || '' });
    }
  });

  for (const key in selectedCardGroup) {
    if (!cardGroup.hasOwnProperty(key)) {
      cardGroup[key] = values[key];
    }
  }

  cardGroup.showGroupTitle = values.showGroupTitle;
  cardGroup.groupTitleTextColor = values.groupTitleTextColor;
  cardGroup.navlinkDisplay = values.navlinkDisplay;
  cardGroup.navlinkActiveCards = values.navlinkActiveCards;
  cardGroup.textColor = values.textColor;
  cardGroup.showButtons = values.showButtons;
  cardGroup.visibilityRule = values.visibilityRule;
  cardGroup.visibilityRuleCustom = values.visibilityRuleCustom;

  return {
    cardGroupId: cardGroupId,
    cardGroupType: selectedCardGroup.type,
    cardGroup: cardGroup,
  };
};

export const buildGenericDashboardCardGroupSchema = (cardGroupType, languages) => Yup.object().shape(
  buildLanguageSchema(
    {
      type: Yup.string(),
      groupTitleEn: Yup.string()
        .required('Card Group Name is required')
        .max(40, 'Card Group Name must be 40 characters or less'),
      groupTitleEs: Yup.string().max(
        40,
        'Card Group Title (Spanish) must be 40 characters or less',
      ),
      showGroupTitle: Yup.bool().required('Show Group Title is required'),
      groupTitleTextColor: Yup.string()
        .when(
          'showGroupTitle', {
            is: true,
            then: (schema) => schema.required('Group Title Text Color is required'),
          },
        ).matches(/^#([a-f0-9]){6}$/i, {
          message: 'Text color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
      showButtons: Yup.bool().required(),
      textColor: Yup.string()
        .required()
        .matches(/^#([a-f0-9]){6}$/i, {
          message: 'Text color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
      backgroundColor: Yup.string()
        .required()
        .matches(/^#([a-f0-9]){6}$/i, {
          message: 'Background color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
      visibilityRuleCustom: Yup.string().nullable().when('visibilityRuleSelect', {
        is: 'custom',
        then: (schema) => schema
          .test('isJSON', 'Invalid JSON input', (value) => {
            if (!value) return true;
            try {
              JSON.parse(value);
              return true;
            } catch (e) {
              return false;
            }
          })
          .required('Visibility Rule is required'),
      }),
    },
    languages,
    {
      groupTitleEn: LANGUAGE_CODES.ENGLISH,
      groupTitleEs: LANGUAGE_CODES.SPANISH,
    },
  ),
);

export const buildNavlinkDashboardCardGroupSchema = (languages) => Yup.object().shape(
  buildLanguageSchema(
    {
      type: Yup.string(),
      groupTitleEn: Yup.string()
        .required('Card Group Name is required')
        .max(30, 'Card Group Name must be 30 characters or less'),
      groupTitleEs: Yup.string().max(
        30,
        'Card Group Title (Spanish) must be 30 characters or less',
      ),
      showGroupTitle: Yup.bool().required('Show Group Title is required'),
      groupTitleTextColor: Yup.string()
        .when(
          'showGroupTitle', {
            is: true,
            then: (schema) => schema.required('Group Title Text Color is required'),
          },
        ).matches(/^#([a-f0-9]){6}$/i, {
          message: 'Text color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
      textColor: Yup.string()
        .required()
        .matches(/^#([a-f0-9]){6}$/i, {
          message: 'Text color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
      navlinkDisplay: Yup.string().required('Navlink display type is required'),
      navlinkActiveCards: Yup.string().required('Navlink Active Cards is required'),
      backgroundColor: Yup.string()
        .when(
          'navlinkDisplay', {
            is: 'icon',
            then: (schema) => schema.required('Background color is required when NavLink Display: Icon, is selected.'),
          },
        )
        .matches(/^#([a-f0-9]){6}$/i, {
          message: 'Background color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
      visibilityRuleCustom: Yup.string().nullable().when('visibilityRuleSelect', {
        is: 'custom',
        then: (schema) => schema
          .test('isJSON', 'Invalid JSON input', (value) => {
            if (!value) return true;
            try {
              JSON.parse(value);
              return true;
            } catch (e) {
              return false;
            }
          })
          .required('Visibility Rule is required'),
      }),
    },
    languages,
    {
      groupTitleEn: LANGUAGE_CODES.ENGLISH,
      groupTitleEs: LANGUAGE_CODES.SPANISH,
    },
  ),
);
