import { Box, Grid, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import Asterisk from 'components/asterisk/asterisk';
import ColorOptions from 'components/colorOptions/colorOptions';
import TabPanel from 'components/tabPanel/tabPanel';
import TextField from 'components/textField/textField';
import { buildDashboardEditStyles } from 'pages/mobileSettings/dashboard/containers/editCard/dashboardEditStyles';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { getLabelByLang } from 'helpers/lang-util';
import Condition from 'components/forms/condition';
import { buildDashboardCardInitializeValues } from '../../../../../components/dashboard/dashboardHelper';
import GozioRadioGroup from '../../../../../components/radioGroup/radioGroup';
import NavLinkCardStepperModal from './navLinkCardStepperModal';
import GozioListItem from '../../../../../components/listItem/gozioListItem';
import VisibilitySelector from '../../../../../components/selector/VisibilitySelector';
import YesNoRadioGroup from '../../../../../components/radioGroup/yesNoRadioGroup';

const NavLinkGroupTabPanel = ({
                                form,
                                formValues,
                                handleCardReorder,
                                handleGroupCardSaved,
                                setDashboardRefreshRequired,
                                languages = [],
                              }) => {
  const theme = useTheme();
  const styles = buildDashboardEditStyles({ theme });

  const cardsLength = formValues?.cards?.length || 0;

  const [selectedNavlinkCard, setSelectedNavlinkCard] = useState(null);

  const activeNavlinkOptions = [
    {
      label: '3 Navlinks',
      value: 3,
    },
    {
      label: '2 Navlinks',
      value: 2,
    },
  ];

  const navlinkDisplayOptions = [
    {
      label: 'Image',
      value: 'image',
    },
    {
      label: 'Icon',
      value: 'icon',
    },
  ];

  return (
    <TabPanel sx={{ ...styles.tabPanel, padding: '24px 24px 0' }} value={0} index={0}>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12}>
          <Field name="groupTitleEn">
            {({ input, meta }) => (
              <TextField
                label={
                  <span>
                    <Asterisk/> Card Group Name
                  </span>
                }
                charLimit={30}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                placeholder="Card Group Name"
                value={input.value}
                onChange={input.onChange}
                fullWidth
              />
            )}
          </Field>
          <Condition when="langES" is={true}>
            <Grid item xs={12} marginTop={'16px'}>
              <Field name="groupTitleEs">
                {({ input, meta }) => (
                  <TextField
                    label={<span>Card Group Name (Spanish)</span>}
                    charLimit={30}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    placeholder="Card Group Name"
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                  />
                )}
              </Field>
            </Grid>
          </Condition>
        </Grid>
      </Box>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12}>
          <Typography sx={styles.displayHeading} variant="subtitle1">
            Display Options
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...styles.displaySubheading, marginTop: '16px' }} variant="subtitle2">
            Display Group Name?
          </Typography>
          <YesNoRadioGroup
            fieldName={'showGroupTitle'}
          />
        </Grid>
        <Condition when="showGroupTitle" is={true}>
          <Grid item marginTop={'16px'}>
            <Field name="groupTitleTextColor">
              {({ input: { value, onChange } }) => (
                <ColorOptions
                  initialColor={value?.toString()}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Group name color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardTextColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Condition>
        <Grid item xs={12}>
          <Typography sx={{ ...styles.displaySubheading, marginTop: '16px' }} variant="subtitle2">
            Card Title Color
          </Typography>
        </Grid>
        <Grid item marginTop={'16px'}>
          <Field name="textColor">
            {({ input: { value, onChange } }) => (
              <ColorOptions
                initialColor={value?.toString()}
                formControl={true}
                getColor={(color) => {
                  onChange(color);
                }}
                label="Card title color"
                required={true}
                inputProps={{
                  'data-test': 'editCardTextColor',
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} marginTop={'16px'}>
          <Typography sx={styles.displaySubheading} variant="subtitle2">
            Number of Active Navlinks
          </Typography>
          <Field name="navlinkActiveCards">
            {({ input }) => (
              <GozioRadioGroup
                value={input.value}
                onChange={
                  (selectedOption) => {
                    input.onChange(Number(selectedOption));
                  }
                }
                alignment="horizontal"
                options={activeNavlinkOptions}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...styles.displaySubheading, marginTop: '16px' }} variant="subtitle2">
            Navlinks Display
          </Typography>
          <Field name="navlinkDisplay">
            {({ input }) => (
              <GozioRadioGroup
                value={input.value}
                onChange={
                  (selectedOption) => {
                    input.onChange(selectedOption);
                  }
                }
                alignment="horizontal"
                options={navlinkDisplayOptions}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} marginTop={'16px'}>
          <Condition when="navlinkDisplay" is="icon">
            <Grid item marginTop={'16px'}>
              <Field name="backgroundColor">
                {({ input: { value, onChange } }) => (
                  <ColorOptions
                    initialColor={value?.toString()}
                    formControl={true}
                    getColor={(color) => {
                      onChange(color);
                    }}
                    label="Background color"
                    required={true}
                    inputProps={{
                      'data-test': 'editCardBackgroundColor',
                    }}
                  />
                )}
              </Field>
            </Grid>
          </Condition>
        </Grid>
        {(<VisibilitySelector/>)}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '14px',
            paddingTop: '5px',
          }}
        >
          <Typography variant="subtitle1" className="required">
            Navlink Cards
          </Typography>
        </Box>
        <Grid>
          <Grid item xs>
            {cardsLength > 0
              && <GozioListItem
                listHeaderName="Title"
                progressHeaderName="Progress"
                enableReorder={true}
                showActive={true}
                options={formValues.cards.map((card, index) => ({
                  id: card.id,
                  order: index,
                  label: getLabelByLang(card.title),
                  active: index < formValues.navlinkActiveCards,
                  validation: card.validation,
                  actions: [
                    {
                      Icon: Edit,
                      hover: 'Edit',
                      onClick: (cardId) => {
                        const selectedCard = formValues.cards.find((item) => item.id === cardId);
                        if (selectedCard) {
                          const initializedCard = buildDashboardCardInitializeValues(selectedCard);
                          initializedCard.cardId = selectedCard.id;
                          initializedCard.cardGroupType = formValues.cardGroupType;
                          initializedCard.cardGroupId = formValues.cardGroupId;
                          setSelectedNavlinkCard(initializedCard);
                        }
                      },
                    },
                  ],
                }))}
                updateOptions={handleCardReorder}
              />
            }
          </Grid>
        </Grid>
      </Box>
      {selectedNavlinkCard && (<NavLinkCardStepperModal
          open={true}
          languages={languages}
          currentValues={selectedNavlinkCard}
          navlinkDisplay={formValues.navlinkDisplay}
          onSave={
            (values) => {
              handleGroupCardSaved(values, form);
              setDashboardRefreshRequired(true);
              setSelectedNavlinkCard(null);
            }
          }
          onClose={() => setSelectedNavlinkCard(null)}
        />
      )}
    </TabPanel>
  );
};

export default NavLinkGroupTabPanel;
