import GozioSelect from './selector';
import { capitalize } from '../../helpers/lang-util';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useFindNetworkVisibilityRule } from '../../hooks/dataHooks/useNetworkVisibilityRule';
import { useCheckRole } from '../../hooks/useCheckGozioAdmin';
import { USER_ROLES } from '../../helpers/permissions-util';
import { Grid, Typography } from '@mui/material';
import Condition from '../forms/condition';
import { TextField as FinalTextField } from 'mui-rff';
import { getFormLabel } from '../../helpers/form-util';
import InfoTooltip from '../infoTooltip/infoTooltip';
import PropTypes from 'prop-types';

const VisibilitySelector = ({
                              selectorFieldName,
                              customFieldName,
                            }) => {
  const isUberAdmin = useCheckRole() === USER_ROLES.UBER_ADMIN;
  const { data: visibilityRules } = useFindNetworkVisibilityRule();

  const visibilityRuleField = useMemo(() => {
    const visibilityRuleOptions = isUberAdmin ? [
      {
        id: 'allUsers',
        label: 'All Users',
      },
      {
        id: 'custom',
        label: 'Custom',
      },
    ] : [
      {
        id: 'allUsers',
        label: 'All Users',
      },
    ];
    return (
      <Field name={selectorFieldName}>
        {({ input }) => (
          <GozioSelect
            input={{ value: input.value, onChange: input.onChange }}
            label="Visibility Rule"
            options={
              visibilityRules
                ? visibilityRuleOptions.concat(visibilityRules.map((rule) => ({
                  id: rule.id,
                  label: capitalize(rule.name),
                })))
                : visibilityRuleOptions
            }
          />
        )}
      </Field>
    );
  }, [isUberAdmin, selectorFieldName, visibilityRules]);

  return (
    <Grid item>
      <Grid container>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: '12px', marginTop: '24px', color: 'text.primary' }}
          >
            Select Visibility Rule
          </Typography>
        </Grid>
        {!isUberAdmin && (<Grid item>
          <InfoTooltip
            sx={{ marginLeft: 1 }}
            title={'This functionality is currently unavailable. Please contact Gozio support to enable this feature.'}
          />
        </Grid>)}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {visibilityRuleField}
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 32 }}>
        <Condition when={selectorFieldName} is="custom">
          <Grid item xs={12}>
            <FinalTextField
              label={getFormLabel('JSON Input')}
              multiline
              name={customFieldName}
              variant="outlined"
              minRows={7}
              maxRows={7}
            />
          </Grid>
        </Condition>
      </Grid>
    </Grid>
  );
};

VisibilitySelector.propTypes = {
  selectorFieldName: PropTypes.string,
  customFieldName: PropTypes.string,
};

VisibilitySelector.defaultProps = {
  selectorFieldName: 'visibilityRuleSelect',
  customFieldName: 'visibilityRuleCustom',
};

export default VisibilitySelector;
