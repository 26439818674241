import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  buildDashboardCardGroupInitializeValues,
  renderDashboardCardGroupTabPanels,
  submitDashboardCardGroup,
} from 'components/dashboard/dashboardHelper';
import GozioDrawer from 'components/drawer/drawer';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import {
  useCreateDashboardCard,
  useDeleteDashboardCard,
  useUpdateDashboardCard,
  useUpdateDashboardCardGroup,
} from 'hooks/dataHooks/useNetworkDashboard';
import useToast from 'hooks/useToast';
import { makeValidate } from 'mui-rff';
import { buildDashboardEditStyles } from 'pages/mobileSettings/dashboard/containers/editCard/dashboardEditStyles';
import PropTypes from 'prop-types';
import React, { forwardRef, Suspense, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { getActionLink, I18N_FIELDS, processUpdatedI18NData } from './editCardHelper';
import { uploadImage } from '../../../../../modules/media';
import { capitalize } from 'lodash';
import {
  buildGenericDashboardCardGroupSchema,
  buildNavlinkDashboardCardGroupSchema,
  getErrorFormFieldMap,
} from './editCardGroupHelper';
import { CARD_GROUP_TYPES } from '../../../../../components/dashboard/cardLayouts/cardhelper';

const EditCardGroupPanel = forwardRef((props, ref) => {
  const theme = useTheme();
  const styles = buildDashboardEditStyles({ theme });

  const { toastNotificationErrorHook } = useToast();

  const {
    languages,
    cardGroupId,
    cardGroupType,
    onEditCardGroupCancelled,
    onEditCardGroupChanged,
    onEditCardGroupSaved,
  } = props;

  const [dashboardRefreshRequired, setDashboardRefreshRequired] = useState(false);

  const [updateCardGroup] = useUpdateDashboardCardGroup();

  const buildDashboardCardGroupSchema = (cardGroupType, languages) => {
    switch (cardGroupType) {
      case CARD_GROUP_TYPES.NAV_LINKS:
        return buildNavlinkDashboardCardGroupSchema(cardGroupType, languages);
      default:
        return buildGenericDashboardCardGroupSchema(cardGroupType, languages);
    }
  };

  const validate = makeValidate(buildDashboardCardGroupSchema(cardGroupType, languages));

  const [updateCard] = useUpdateDashboardCard();
  const [createCard] = useCreateDashboardCard();
  const [deleteCard] = useDeleteDashboardCard();

  const buildCardDataForUpdateOrCreate = async (values) => {
    const updatedData = {};
    let backgroundImageUrl;
    let iconUrl;
    let icon;

    if (values.iconUrl?.file) {
      const iconImageData = await uploadImage(values.iconUrl.file);

      if (iconImageData) {
        iconUrl = iconImageData.secureUrl;
      }
    }

    if (values.backgroundImageUrl?.file) {
      const backgroundImageData = await uploadImage(
        values.backgroundImageUrl.file,
      );

      if (backgroundImageData) {
        backgroundImageUrl = backgroundImageData.secureUrl;
      }
    }

    if (values.icon?.file) {
      const iconData = await uploadImage(values.icon.file);

      if (iconData.id) {
        icon = iconData.id;
      }
    }

    Object.keys(values).forEach((key) => {
      const index = key.length - 2;
      const fieldName = key.substring(0, index);
      const isI18NField = !!I18N_FIELDS.find((f) => f.key === fieldName);
      const value = values[key];
      if (isI18NField) {
        const lang = key.substring(index).toLowerCase();
        processUpdatedI18NData({ fieldName, lang, value, updatedData });
      } else {
        let updatedActionLink;
        switch (key) {
          case 'backgroundImageUrl':
            updatedData.backgroundImageUrl = backgroundImageUrl;
            break;
          case 'iconUrl':
            updatedData.iconUrl = iconUrl;
            break;
          case 'icon':
            updatedData.icon = icon;
            break;
          case 'linkType':
            updatedActionLink = getActionLink(values);
            updatedData.linkData = updatedActionLink;
            break;
          default:
            break;
        }
      }
    });
    return updatedData;
  };

  const handleCardReorder = (updatedListOptions, form) => {
    const sortedCards = [];
    updatedListOptions.forEach((opt, index) => {
      const formValues = form.getState().values;
      const card = formValues.cards.find(
        (card) => card.id === opt.id,
      );
      sortedCards.push({
        ...card,
        order: index,
      });
    });
    form.change('cards', sortedCards);
    handleFormChange(form);
  };

  const onDeleteCard = async (cardId, form) => {
    const cardGroupValues = form.getState().values;
    let deletedCardId = null;
    if (cardId) {
      try {
        const deleteResult = await deleteCard({ variables: { cardId } });
        deletedCardId = deleteResult?.data?.deleteNetworkDashboardCard?.id;
      } catch (err) {
        toastNotificationErrorHook(err.message);
      }
    }
    if (deletedCardId) {
      const updatedCards = [...cardGroupValues.cards];

      const existingIndex = updatedCards.findIndex((card) => card.id === deletedCardId);

      if (existingIndex !== -1) {
        updatedCards.splice(existingIndex, 1);
      }
      form.change('cards', updatedCards);
    }
  };

  const onSubmitCard = async (cardValues, form) => {
    const cardGroupValues = form.getState().values;
    // Create an empty object to put all the necessary fields in
    const cardForSubmission = await buildCardDataForUpdateOrCreate(cardValues);

    // Cleanup some of the data
    delete cardForSubmission.customLogicTitle;
    if (cardForSubmission.backgroundImageUrl === '') {
      cardForSubmission.backgroundImageUrl = null;
    }
    if (cardForSubmission.iconUrl === '') {
      cardForSubmission.iconUrl = null;
    }
    if (cardForSubmission.icon === '') {
      cardForSubmission.icon = null;
    }

    try {
      const cardId = cardValues.cardId;
      const cardGroupId = cardValues.cardGroupId;

      // If no card id is passed, we need to create a card, otherwise, update the card
      let result = null;
      if (!cardId) {
        const createResult = await createCard({ variables: { cardGroupId, input: cardForSubmission } });
        result = createResult?.data?.createNetworkDashboardCard;
      } else {
        const networkResult = await updateCard({ variables: { id: cardId, input: cardForSubmission } });
        result = networkResult?.data?.updateNetworkDashboardCard;
      }
      if (result) {
        const updatedCards = [...cardGroupValues.cards || []];

        const existingIndex = updatedCards.findIndex((card) => card.id === result.id);

        if (existingIndex !== -1) {
          updatedCards[existingIndex] = result;
        } else {
          updatedCards.push(result);
        }
        form.change('cards', updatedCards);
      }
    } catch (err) {
      toastNotificationErrorHook(err.message);
    }
  };

  const onSubmitCardGroup = async (values = {}) => {
    try {
      await submitDashboardCardGroup({
        cardGroupId,
        initialValues,
        values,
        updateCardGroup,
        onEditCardGroupSaved,
        setInitialValues,
        toastNotificationErrorHook,
      });
    } catch (err) {
      toastNotificationErrorHook(err.message);
    }
  };

  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    if (!initialValues) {
      setInitialValues(buildDashboardCardGroupInitializeValues(props));
    }
  }, [initialValues, props]);

  const handleFormChange = (form) => {
    onEditCardGroupChanged({
      cardGroupId,
      values: { ...form.getState().values },
      languages,
    });
  };

  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDrawerClose = (form) => {
    const { dirty, errors } = form.getState();
    if (!dirty && Object.keys(errors).length === 0) {
      onEditCardGroupCancelled(dashboardRefreshRequired);
    } else {
      setIsClosing(true);
    }
  };

  return (
    <Box ref={ref}>
      <Form
        key={JSON.stringify(initialValues) || 'init'}
        initialValues={initialValues}
        mutators={{
          setCategoryType: ([value], state, utils) => {
            utils.changeValue(state, 'categoryType', () => value);
          },
        }}
        onSubmit={onSubmitCardGroup}
        validate={validate}
        sx={styles.content}
      >
        {({ handleSubmit, form, values, submitting, errors }) => (
          <form
            onChange={() => handleFormChange(form)}
            onSubmit={async (event) => {
              if (Object.keys(errors).length) {
                const errorMessage = Object.keys(errors)
                  .map((fieldKey) => {
                    if (fieldKey === 'expires') {
                      return 'The Expires on date is required';
                    }
                    return errors[
                      typeof fieldKey === 'object' ? fieldKey?.[0] : fieldKey
                      ];
                  })
                  .join('; ');
                toastNotificationErrorHook(errorMessage);
              }

              await handleSubmit(event);
            }}
          >
            <GozioDrawer
              open={true}
              disableCancel={true}
              greyOutConfirm={submitting}
              onClose={() => handleDrawerClose(form)}
              onConfirm={async () => {
                setIsSubmitting(true);
                await form.submit();
                setIsSubmitting(false);
              }}
              title={`Edit ${capitalize(cardGroupType)} Card Group`}
              top={70}
              width={581}
            >
              <Suspense fallback={<Loading/>}>
                {renderDashboardCardGroupTabPanels({
                  form,
                  handleCardReorder,
                  handleGroupCardSaved: onSubmitCard,
                  onDeleteCard,
                  setDashboardRefreshRequired,
                  props,
                  values,
                })}
              </Suspense>
            </GozioDrawer>
            <FormWatcher
              formRenderProps={{ form }}
              errorFormFieldMap={getErrorFormFieldMap()}
              isClosing={isClosing}
              isSubmitting={isSubmitting}
              onClose={() => {
                setIsClosing(false);
                setIsSubmitting(false);
              }}
              onContinue={() => {
                onEditCardGroupCancelled();
                return true;
              }}
            />
          </form>
        )}
      </Form>
    </Box>
  );
});

EditCardGroupPanel.propTypes = {
  cardGroupId: PropTypes.string.isRequired,
  cardGroupType: PropTypes.string.isRequired,
};

export default EditCardGroupPanel;
